<template>
    <header id="header">
        <div class="main-content-box">
            <div class="header-info">

                <div class="header-zhanwei"></div>

                <div class="header-logo">
                    <img src="../../assets/logo.png" alt="logo">
                </div>
                <nav class="header-nav">
                    <ul>
                        <li v-for="item in linkList"><router-link :to="item.link_src">{{ item.link_field }}</router-link></li>
                    </ul>
                </nav>
                <div class="header-search">
                    <i class="el-icon-search float-right"></i>
                    <i class="el-icon-menu float-right" @click="drawer=true"></i>
                </div>


                <el-drawer
                    title="导航"
                    v-model="drawer"
                    :with-header="false">
                    <ul class="sj-header-nav">
                        <li v-for="item in linkList"><router-link :to="item.link_src">{{ item.link_field }}</router-link></li>
                    </ul>
                </el-drawer>

            </div>
        </div>
    </header>
</template>

<script>


export default {

    name: 'HeaderNav',
    components: {},
    data() {
        return {
            drawer: false,
            linkList: [
                {
                    link_src: '/home',
                    link_field: '首页',
                },
                {
                    link_src: '/life',
                    link_field: '生活',
                },
                {
                    link_src: '/skills',
                    link_field: '技术',
                },
                {
                    link_src: '/about',
                    link_field: '关于我',
                },
                {
                    link_src: '/contact',
                    link_field: '联系',
                },
            ]
        }
    },

    watch: {
        $route(to, from) {
            this.drawer = false;
        }
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.router-link-active {
    color: #e74c3c !important;
}

.header-info {
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-logo{
    width: 85px;

}
.header-logo img{
    width: 100%;
}

.header-nav {
    margin: 0 auto;
}
.header-nav ul{
    display: flex;
    justify-content: space-between;
    width: 480px;
}
.header-nav li{
    flex-basis: 75px;
    text-align: center;
}
.header-nav a{
    color: #212529;
    font-size: 16px;
    display: block;
    text-transform: none;
}
.header-nav li:hover a{
    color: #e74c3c;
}


.header-search {
    flex-basis: 85px;
    font-size: 22px;
}
.el-icon-menu {
    font-size: 20px;
    color: #212529;
    border: none!important;
    background-color: transparent;
    margin: 0 auto!important;
    padding: 0 0!important;
}
.el-icon-menu:hover{
    background-color: transparent;
    color: #212529;
}


.sj-header-nav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 195px;
    margin: 15px 0 0 10px;
}
.sj-header-nav li{
    flex-basis: 30px;
}
.sj-header-nav li a{
    font-size: 15px;
    display: block;
}





@media screen and (min-width: 990px) and (max-width: 9999px) {

    .el-icon-menu {
        display: none;
    }
}


@media screen and (min-width: 0px) and (max-width: 990px) {

    .header-nav {
        display: none;
    }
    .header-logo {
        width: 62px;
        margin: auto;
    }
    .header-search{
        flex-basis: 62px;
    }
    .el-icon-search {
        display: none;
    }
    .header-zhanwei {
        flex-basis: 62px;
    }


}


</style>
