import {createRouter, createWebHistory} from 'vue-router'


const Home = () => import('../views/Home.vue')      // 主页
const Life = () => import('../views/Life.vue')      // 生活
const Skills = () => import('../views/Skills.vue')  // 技术
const AboutMe = () => import('../views/AboutMe.vue')// 关于我
const Contact = () => import('../views/Contact.vue')// 联系
const Article = () => import('../views/Article.vue')// 文章
const PublicArticle = () => import('../views/PublicArticle.vue')// 文章




const routes = [
    {
        path: '/',
        redirect: {name: 'Home'},
    },

    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            title: '主页 | 赵孟'
        },
    },
    {
        path: '/life',
        name: 'Life',
        component: Life,
        meta: {
            title: '生活 | 赵孟'
        },
    },
    {
        path: '/skills',
        name: 'Skills',
        component: Skills,
        meta: {
            title: '技术 | 赵孟'
        },
    },
    {
        path: '/about',
        name: 'About',
        component: AboutMe,
        meta: {
            title: '关于我 | 赵孟'
        },
    },

    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: '联系我 | 赵孟'
        },
    },

    {
        path: '/article/:id',
        name: 'Article',
        component: Article
    },

    {
        path: '/public',
        name: 'PublicArticle',
        component: PublicArticle,
        meta: {
            title: '笔记发布'
        },
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
