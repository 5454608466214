<template>

    <header-nav></header-nav>

    <main>
        <router-view></router-view>
    </main>

    <footer-nav></footer-nav>
</template>

<script>
    import HeaderNav from "./components/public/HeaderNav";
    import FooterNav from "./components/public/FooterNav";
    export default {
        name: 'app',
        components: {
            HeaderNav,
            FooterNav,
        }
    }
</script>
<style lang="less">

</style>
