<template>
    <footer id="footer">
        <div class="main-content-box">
            <div class="footer-info-box">
                <div class="footer-logo">
                    <span>MrZhao</span>
                </div>

                <div class="footer-beian">
                    <span>© 2021 Blog 版权所有 备案号：京ICP备19010091号-2</span>
                </div>

                <div class="footer-info">
                    <span>服务条款 &nbsp; 隐私政策 &nbsp; 法律申明</span>
                </div>


            </div>
        </div>

    </footer>
</template>

<script>
export default {
    name: "FooterNav"
}
</script>

<style scoped>
#footer{
    width: 100%;
    background-color: #fafafa;
}


.footer-all span{
    display: block;
}


.footer-info-box{
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    /*height: 105px;*/
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    padding: 20px 0;
}

.footer-info,.footer-beian {
    color: #999999;
}
.footer-beian{
    margin: 20px 0;
}
.footer-logo {
    font-size: 25px;
}

.footer-info-box div{
    text-align: center;
    line-height: 15px;
}





@media screen and (min-width: 0px) and (max-width: 600px) {


}


</style>
