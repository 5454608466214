import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './static/css/public.css'
import './static/css/iconfont.css'

import './static/wow_static/css/animate.css';


const app = createApp(App)

import config from './config/config'
app.use(config)

// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// app.use(ElementUI)

import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
app.use(ElementPlus)
import infiniteScroll from "vue-infinite-scroll";
// app.use(infiniteScroll);

import vueAxios from 'vue-axios'
import axios from "axios";
app.use(vueAxios, axios)
// axios.defaults.baseURL = 'http://192.168.3.35:8888/v2';
axios.defaults.baseURL = 'https://api.mrzhao520.cn/v2';
// app.prototype.$axios = axios




import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';



VMdPreview.use(githubTheme);
app.use(VMdPreview);


// 按需引入 highlightjs 的语言包，此处以 json 为例
import json from 'highlight.js/lib/languages/json';
import sql from 'highlight.js/lib/languages/sql';
import apache from 'highlight.js/lib/languages/apache';
import python from 'highlight.js/lib/languages/python';
import java from 'highlight.js/lib/languages/java';
import django from 'highlight.js/lib/languages/django';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import bash from 'highlight.js/lib/languages/bash';
import nginx from 'highlight.js/lib/languages/nginx';
import ini from 'highlight.js/lib/languages/ini';

VueMarkdownEditor.use(githubTheme, {
    extend(md, hljs) {
        hljs.registerLanguage('json', json);
        hljs.registerLanguage('sql', sql);
        hljs.registerLanguage('apache', apache);
        hljs.registerLanguage('python', python);
        hljs.registerLanguage('django', django);
        hljs.registerLanguage('java', java);
        hljs.registerLanguage('dockerfile', dockerfile);
        hljs.registerLanguage('bash', bash);
        hljs.registerLanguage('nginx', nginx);
        hljs.registerLanguage('ini', ini);

    },
});
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';
VueMarkdownEditor.use(createTipPlugin());


import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
VueMarkdownEditor.use(createEmojiPlugin());


import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
VueMarkdownEditor.use(createLineNumbertPlugin());


import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
VueMarkdownEditor.use(createCopyCodePlugin());





app.use(VueMarkdownEditor);
app.use(router).mount('#app')

router.beforeEach((to, from, next) => {
    /* 路由发生变化时，遍历修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})


