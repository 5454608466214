export default {
    install(app, options)
    {
        app.config.globalProperties.$imgUrl = 'https://img.mrzhao520.cn';
        app.config.globalProperties.$videoUrl = 'https://img.mrzhao520.cn/video';
        // app.prototype.getTitle = {
        //     title:'',
        //     isBack: true,
        //     isAdd:  false,
        // };
        // app.prototype.showFootTab = {
        //     isShow:false,
        //     active:0,
        // }
    }
}